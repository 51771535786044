import Button from '@eg/elements/Button';
import { REQUEST_FORM_LABELS } from 'config';
import { SubmitButtonProps } from './SubmitButton.types';

export const SubmitButton = ({ disabled }: SubmitButtonProps) => {
  return (
    <Button
      type="submit"
      variant="primary"
      data-testid="SubmitButton"
      disabled={disabled}
    >
      {REQUEST_FORM_LABELS.download}
    </Button>
  );
};
