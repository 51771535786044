import {
  SET_API_ERRORS,
  SET_BACKEND_ERRORS,
  SET_INITIAL_STATE,
  SET_INSURANCE_START_DATES_RANGE,
  SET_IS_API_ERRORS,
  SET_PAYMENT_METHODS,
} from 'store/actions';

export const requestActions = {
  initialState: (data: string[]) => {
    return { type: SET_INITIAL_STATE, payload: data };
  },
  paymentMethods: (data: object[]) => {
    return { type: SET_PAYMENT_METHODS, payload: data };
  },
  insuranceStartDates: (data: string[]) => {
    return { type: SET_INSURANCE_START_DATES_RANGE, payload: data };
  },
  backEndErrors: (errors: string[]) => {
    return { type: SET_BACKEND_ERRORS, payload: errors };
  },
  ergoApiErrors: (errors: string[]) => {
    return { type: SET_API_ERRORS, payload: errors };
  },
  noApiErrors: { type: SET_IS_API_ERRORS, payload: false },
  thereAreApiErrors: { type: SET_IS_API_ERRORS, payload: true },
};
