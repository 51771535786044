import { ForwardedRef, forwardRef } from 'react';
import classNames from 'classnames';
import { TextFieldProps } from './TextField.types';

export const TextField = forwardRef(
  (
    {
      name,
      isError,
      maxLength,
      minLength,
      typeNumber,
      disabled,
      ...formProps
    }: TextFieldProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const className = classNames('ee_input', isError && 'ee_is-invalid');

    return (
      <input
        className={className}
        type={typeNumber ? 'number' : 'text'}
        maxLength={maxLength}
        minLength={minLength}
        ref={ref}
        name={name}
        {...formProps}
        disabled={disabled}
      />
    );
  },
);
