import {
  FormDataProps,
  RequestsProps,
  UserInterfaceProps,
} from 'types/app.types';
import { loaderLabels } from 'config/loader-labels';

export const UserInterfaceInitialState: UserInterfaceProps = {
  isAppInitialized: false,
  loaderState: {
    show: true,
    infoLabel: loaderLabels.pageIsLoading,
  },
  isUserAuthorized: false,
  isButtonLocked: false,
};

export const RequestsInitialState: RequestsProps = {
  initialState: [],
  insuranceStartDateRange: [],
  paymentMethods: [],
  apiErrors: [],
  isApiErrors: false,
  backendErrors: [],
};

export const FormDataInitialState: FormDataProps = {
  conceptName: '',
  actionNumber: '',
  insuranceStartDate: '',
  entryAgeFrom: 0,
  entryAgeTo: 0,
  paymentMethod: '',
  intermediaryNumber: '',
  aoMailing: false,
  actionType: '',
  moreVariantsOnRequest: '',
  variantsOnRequest: {
    additionalToothPreservation: '',
    additionalDentures: '',
    variants: [],
  },
  multiPerson: false,
};

export const StoreInitialState = {
  UserInterfaceInitialState,
  RequestsInitialState,
  FormDataInitialState,
};
