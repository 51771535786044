import {
  RequestFormFields,
  ToothPreservationOfferedChoices,
  DenturesOfferedChoices,
  ChangeFrom,
  ChangeTo,
  AdditionalToothPreservation,
  AdditionalDentures,
  UpsellingAdditionalDentures,
  UpsellingAdditionalToothPreservation,
  BaseRate,
  ConstrictionTariffs,
} from 'types';

export const REQUEST_FORM_LABELS = {
  [RequestFormFields.conceptName]: 'Name des Konzeptes',
  [RequestFormFields.actionNumber]: 'Aktionsnr.',
  [RequestFormFields.insuranceStartDate]: 'Versicherungsbeginn',
  [RequestFormFields.entryAgeFrom]: 'Eintrittsalter von',
  [RequestFormFields.entryAgeTo]: 'Eintrittsalter bis',
  [RequestFormFields.paymentMethod]: 'Zahlweise',
  [RequestFormFields.intermediaryNumber]:
    'Vermittlernummer (nur Cooperation Sales)',
  [RequestFormFields.aoMailing]: 'AO Mailing',
  [RequestFormFields.actionType]: 'Maßnahmenart',
  [RequestFormFields.moreVariantsOnRequest]: 'Mehrere Varianten auf Antrag?',
  [RequestFormFields.multiPerson]: 'Mehrpersonen',
  toothPreservationOffered: 'Angebotener Zahnerhalt',
  denturesOffered: 'Angebotener Zahnersatz',
  comboOfBoth: 'Kombi aus beiden?',
  download: 'Download',
  changeFrom: 'Wechsel von',
  changeTo: 'Wechsel in',
  additionalTeethPreservation: 'Zusätzlich Zahnerhalt',
  additionalDentures: 'Zusätzlich Zahnersatz',
  BaseRate: 'Bestandstarif',
  ConstrictionTariffs: 'Ergänzungstarif/e',
  upsellingAdditionalToothPreservation: 'Zusätzlich Zahnerhalt',
  upsellingAdditionalDentures: 'Zusätzlich Zahnersatz',
  apiErrorsOccurred: 'Ein Fehler ist aufgetreten:',
};

export const REQUEST_FORM_VARIANTS_ON_REQUEST_CHOICES = {
  empty: '',
  none: 'Nein',
  two: '2',
  three: '3',
};

export const REQUEST_FORM_PAYMENT_METHOD_CHOICES = {
  monthly: 'Monatlich',
  quarterly: 'Vierteljährlich',
  halfYearly: 'Halbhalbjährlich',
  yearly: 'Jährlich',
};

export const REQUEST_FORM_ACTION_TYPE_CHOICES = {
  standard: 'Standard',
  tarifweschel: 'Tarifwechsel',
  upselling: 'Upselling',
};

export const REQUEST_FORM_TOOTH_PRESERVATION_OFFERED_CHOICES = {
  [ToothPreservationOfferedChoices.ZBB]: 'ZBB',
  [ToothPreservationOfferedChoices.ZBB_ZBE]: 'ZBB+ZBE',
  [ToothPreservationOfferedChoices.DVB]: 'DVB',
  [ToothPreservationOfferedChoices.DVB_DVE]: 'DVB+DVE',
};

export const REQUEST_FORM_DENTURES_OFFERED_CHOICES = {
  [DenturesOfferedChoices.ZEF]: 'ZEF',
  [DenturesOfferedChoices.ZZP]: 'ZZP',
  [DenturesOfferedChoices.ZAB]: 'ZAB',
  [DenturesOfferedChoices.ZAB_ZAE]: 'ZAB+ZAE',
  [DenturesOfferedChoices.DS75]: 'DS75',
  [DenturesOfferedChoices.DS75_DS90]: 'DS75+DS90',
  [DenturesOfferedChoices.DS75_DS90_DS100]: 'DS75+DS90+DS100',
};

// Wechsel von
export const REQUEST_FORM_CHANGE_FROM_CHOICES = {
  [ChangeFrom.ZEE]: 'ZEE',
  [ChangeFrom.ZEF]: 'ZEF',
  [ChangeFrom.ZZP]: 'ZZP',
  [ChangeFrom.ZAB]: 'ZAB',
  [ChangeFrom.ZAB_ZAE]: 'ZAB+ZAE',
  [ChangeFrom.ZBB]: 'ZBB',
  [ChangeFrom.ZBB_ZBE]: 'ZBB+ZBE',
  [ChangeFrom.ZAB_ZAE_ZBB_ZBE]: 'ZAB+ZAE+ZBB+ZBE',
};

// Wechsel in
export const REQUEST_FORM_CHANGE_TO_CHOICES = {
  [ChangeTo.ZAB]: 'ZAB',
  [ChangeTo.ZAB_ZAE]: 'ZAB+ZAE',
  [ChangeTo.DS75]: 'DS75',
  [ChangeTo.DS75_DS90]: 'DS75+DS90',
  [ChangeTo.DS75_DS90_DS100]: 'DS75+DS90+DS100',
  [ChangeTo.DVB]: 'DVB',
  [ChangeTo.DVB_DVE]: 'DVB+DVE',
  [ChangeTo.DS75_DS90_DVB]: 'DS75+DS90+DVB',
  [ChangeTo.DS75_DS90_DVB_DVE]: 'DS75+DS90+DVB+DVE',
  [ChangeTo.DS75_DS90_DS100_DVB]: 'DS75+DS90+DS100+DVB',
  [ChangeTo.DS75_DS90_DS100_DVB_DVE]: 'DS75+DS90+DS100+DVB+DVE',
};

// tarifwechsel - zusatzlicher zahnerhalt
export const REQUEST_FORM_ADDITIONAL_TOOTH_PRESERVATION_CHOICES = {
  [AdditionalToothPreservation.DVB]: 'DVB',
  [AdditionalToothPreservation.DVE]: 'DVE',
  [AdditionalToothPreservation.DVB_DVE]: 'DVB+DVE',
  [AdditionalToothPreservation.ZBB]: 'ZBB',
  [AdditionalToothPreservation.ZBE]: 'ZBE',
  [AdditionalToothPreservation.ZBB_ZBE]: 'ZBB+ZBE',
};

// tarifwechsel - zusatzlicher zahnersatz
export const REQUEST_FORM_ADDITIONAL_DENTURES_CHOICES = {
  [AdditionalDentures.ZEF]: 'ZEF',
  [AdditionalDentures.ZZP]: 'ZZP',
  [AdditionalDentures.ZAB]: 'ZAB',
  [AdditionalDentures.ZAB_ZAE]: 'ZAB+ZAE',
  [AdditionalDentures.DS75]: 'DS75',
  [AdditionalDentures.DS75_DS90]: 'DS75+DS90',
  [AdditionalDentures.DS75_DS90_DS100]: 'DS75+DS90+DS100',
};

export const REQUEST_FORM_UPSELLING_BASE_RATE_CHOICES = {
  [BaseRate.DS75]: 'DS75',
  [BaseRate.DVB]: 'DVB',
  [BaseRate.ZAB]: 'ZAB',
  [BaseRate.ZBB]: 'ZBB',
  [BaseRate.ZEF]: 'ZEF',
  [BaseRate.ZEG]: 'ZEG',
  [BaseRate.ZZP]: 'ZZP',
  [BaseRate.ZES]: 'ZES',
};

// use plus signs in strings ('+')
export const REQUEST_FORM_UPSELLING_CONSTRICTION_TARIFFS_CHOICES = {
  [ConstrictionTariffs.ZAB]: 'ZAB',
  [ConstrictionTariffs.ZAE]: 'ZAE',
  [ConstrictionTariffs.ZAB_ZAE]: 'ZAB+ZAE',
  [ConstrictionTariffs.ZEF]: 'ZEF',
  [ConstrictionTariffs.ZZP]: 'ZZP',
  [ConstrictionTariffs.DS75]: 'DS75',
  [ConstrictionTariffs.DS90]: 'DS90',
  [ConstrictionTariffs.DS100]: 'DS100',
  [ConstrictionTariffs.DS75_DS90]: 'DS75+DS90',
  [ConstrictionTariffs.DS75_DS90_DS100]: 'DS75+DS90+DS100',
  [ConstrictionTariffs.DS90_DS100]: 'DS90+DS100',
  [ConstrictionTariffs.ZBB]: 'ZBB',
  [ConstrictionTariffs.ZBE]: 'ZBE',
  [ConstrictionTariffs.ZBB_ZBE]: 'ZBB+ZBE',
  [ConstrictionTariffs.DVB]: 'DVB',
  [ConstrictionTariffs.DVE]: 'DVE',
  [ConstrictionTariffs.DVB_DVE]: 'DVB+DVE',
};

// upselling - Zusätzlich Zahnerhalt
export const REQUEST_FORM_UPSELLING_ADDITIONAL_TOOTH_PRESERVATION_CHOICES = {
  [UpsellingAdditionalToothPreservation.ZBB]: 'ZBB',
  [UpsellingAdditionalToothPreservation.ZBE]: 'ZBE',
  [UpsellingAdditionalToothPreservation.ZBB_ZBE]: 'ZBB+ZBE',
  [UpsellingAdditionalToothPreservation.DVB]: 'DVB',
  [UpsellingAdditionalToothPreservation.DVE]: 'DVE',
  [UpsellingAdditionalToothPreservation.DVB_DVE]: 'DVB+DVE',
};

// upselling - Zusätzlich Zahnersatz
export const REQUEST_FORM_UPSELLING_ADDITIONAL_DENTURES_CHOICES = {
  [UpsellingAdditionalDentures.ZAB]: 'ZAB',
  [UpsellingAdditionalDentures.ZAE]: 'ZAE',
  [UpsellingAdditionalDentures.ZAB_ZAE]: 'ZAB+ZAE',
  [UpsellingAdditionalDentures.ZEF]: 'ZEF',
  [UpsellingAdditionalDentures.ZZP]: 'ZZP',
  [UpsellingAdditionalDentures.DS75]: 'DS75',
  [UpsellingAdditionalDentures.DS90]: 'DS90',
  [UpsellingAdditionalDentures.DS100]: 'DS100',
  [UpsellingAdditionalDentures.DS75_DS90]: 'DS75+DS90',
  [UpsellingAdditionalDentures.DS75_DS90_DS100]: 'DS75+DS90+DS100',
  [UpsellingAdditionalDentures.DS90_DS100]: 'DS90+DS100',
};
