import { useSelector, useDispatch } from 'react-redux';

// ergo element imports
import FormSection from '@eg/elements/FormSection';
import FormRow from '@eg/elements/FormRow';

// custom components imports
import { SelectField } from 'components/SelectField/SelectField';
import { TextField } from 'components/TextField/TextField';
import { InputErrorBox } from 'components/InputErrorBox';
import { GridRow } from 'components/GridRow';
import { Grid } from 'components/Grid';
import { Box } from 'components/Box';
import { Column } from 'components/Column';

// types imports
import { StoreProps } from 'types/app.types';
import {
  REQUEST_FORM_ACTION_TYPE_CHOICES,
  REQUEST_FORM_ADDITIONAL_DENTURES_CHOICES,
  REQUEST_FORM_ADDITIONAL_TOOTH_PRESERVATION_CHOICES,
  REQUEST_FORM_CHANGE_FROM_CHOICES,
  REQUEST_FORM_CHANGE_TO_CHOICES,
  REQUEST_FORM_DENTURES_OFFERED_CHOICES,
  REQUEST_FORM_LABELS,
  REQUEST_FORM_TOOTH_PRESERVATION_OFFERED_CHOICES,
  REQUEST_FORM_UPSELLING_ADDITIONAL_DENTURES_CHOICES,
  REQUEST_FORM_UPSELLING_ADDITIONAL_TOOTH_PRESERVATION_CHOICES,
  REQUEST_FORM_UPSELLING_BASE_RATE_CHOICES,
  REQUEST_FORM_UPSELLING_CONSTRICTION_TARIFFS_CHOICES,
  REQUEST_FORM_VARIANTS_ON_REQUEST_CHOICES,
} from 'config';
import { DimmedSection } from 'components/SubmitButtonSection/DimmedSection';
import {
  ActionTypes,
  AdditionalDentures,
  AdditionalToothPreservation,
  BaseRate,
  ChangeFrom,
  ChangeTo,
  ConstrictionTariffs,
  DenturesOfferedChoices,
  MoreVariantsOnRequestChoices,
  RequestFormFields,
  ToothPreservationOfferedChoices,
  UpsellingAdditionalDentures,
  UpsellingAdditionalToothPreservation,
} from 'types';
import Checkbox from '@eg/elements/Checkbox';
import { SubmitButton } from 'components/SubmitButton/SubmitButton';
import {
  createOptionsArray,
  enforceDigitsOnly,
  applyGermanUmlauts,
} from 'helpers';
import { dispatchActions } from 'store/actions/dispatch-actions';
import { fieldsToClear } from 'store/constants/fields-to-clear';
import { useId } from 'react';
import { RequestFormProps } from './RequestForm.types';
// styles imports
import styles from './RequestForm.module.scss';

export const RequestForm: React.FC<RequestFormProps> = ({
  onSubmit,
  errors,
  register,
  actionType,
  trigger,
  unregister,
}) => {
  const dispatch = useDispatch();
  const request = useSelector((state: StoreProps) => state.requests);
  const uiState = useSelector((state: StoreProps) => state.userInterface);
  const formData = useSelector((state: StoreProps) => state.formData);
  const { paymentMethods, insuranceStartDateRange, apiErrors, isApiErrors } =
    request;
  const { isButtonLocked } = uiState;
  const isVermittlerNumberError = apiErrors && apiErrors.length > 0;
  const vermittlerNumberErrorMsg = () => {
    if (apiErrors && apiErrors.length > 0) {
      return apiErrors[0];
    }
    return undefined;
  };

  const handleClearFormFields = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === MoreVariantsOnRequestChoices.none) {
      fieldsToClear.caseNone.forEach((item) => unregister(item));
    }
    if (e.target.value === MoreVariantsOnRequestChoices.two) {
      fieldsToClear.caseOne.forEach((item) => unregister(item));
    }
  };

  const handleClearFieldsOnActionTypeChange = () => {
    fieldsToClear.caseClearAll.forEach((item) => unregister(item));
  };
  const key = useId();
  return (
    <div className={styles.root}>
      <FormSection>
        <form data-testid="request-form" onSubmit={onSubmit}>
          <Box>
            {isApiErrors && (
              <DimmedSection data-testid="apiErrorsSection">
                <p className={styles.errorsContainerLabel}>
                  {REQUEST_FORM_LABELS.apiErrorsOccurred}
                </p>
                {apiErrors.map((apiError) => (
                  <p className={styles.errorsMap} key={key}>
                    {apiError}
                  </p>
                ))}
              </DimmedSection>
            )}
            {/* concept name */}
            <Grid>
              <GridRow>
                <Column span_xs={12} span_s={4}>
                  <FormRow
                    className={styles.formRow}
                    label={REQUEST_FORM_LABELS.conceptName}
                    data-testid={RequestFormFields.conceptName}
                  >
                    <InputErrorBox
                      input={
                        <TextField
                          data-testid="conceptName"
                          {...register(`conceptName`, {
                            onChange: (e) =>
                              dispatch(
                                dispatchActions.formActions.conceptName(
                                  e.target.value,
                                ),
                              ),
                          })}
                          name="conceptName"
                          isError={!!errors?.conceptName}
                          disabled={isButtonLocked}
                        />
                      }
                      message={errors?.conceptName?.message}
                    />
                  </FormRow>
                </Column>
                {/* concept name */}

                {/* action Number */}
                <Column span_xs={12} span_s={4}>
                  <FormRow
                    className={styles.formRow}
                    label={REQUEST_FORM_LABELS.actionNumber}
                    data-testid={RequestFormFields.actionNumber}
                  >
                    <InputErrorBox
                      input={
                        <TextField
                          disabled={isButtonLocked}
                          minLength={4}
                          maxLength={4}
                          {...register(`actionNumber`, {
                            onChange: (e) => {
                              const enforcedDigits = enforceDigitsOnly(e);
                              dispatch(
                                dispatchActions.formActions.actionNumber(
                                  enforcedDigits,
                                ),
                              );
                            },
                          })}
                          name="actionNumber"
                          isError={!!errors?.actionNumber}
                        />
                      }
                      message={errors?.actionNumber?.message}
                    />
                  </FormRow>
                </Column>
                {/* action Number */}
              </GridRow>
            </Grid>

            {/* insurance Start DateRange */}
            <GridRow>
              <Column span_xs={12} span_s={4}>
                <FormRow
                  className={styles.formRow}
                  label={REQUEST_FORM_LABELS.insuranceStartDate}
                  data-testid={RequestFormFields.insuranceStartDate}
                >
                  <InputErrorBox
                    input={
                      <SelectField
                        disabled={isButtonLocked}
                        showPlaceholder={false}
                        useEmptyOptionPlaceholder
                        {...register('insuranceStartDate', {
                          onChange: (e) =>
                            dispatch(
                              dispatchActions.formActions.insuranceStartDate(
                                e.target.value,
                              ),
                            ),
                        })}
                        name="insuranceStartDate"
                        options={insuranceStartDateRange.map((date) => ({
                          name: date,
                          value: date,
                        }))}
                        isError={!!errors?.insuranceStartDate}
                      />
                    }
                    message={errors?.insuranceStartDate?.message}
                  />
                </FormRow>
              </Column>
              {/*  insurance Start DateRange  */}

              {/* entry Age From */}
              <Column span_xs={12} span_s={4}>
                <FormRow
                  className={styles.formRow}
                  label={REQUEST_FORM_LABELS.entryAgeFrom}
                  data-testid={RequestFormFields.entryAgeFrom}
                >
                  <InputErrorBox
                    input={
                      <TextField
                        disabled={isButtonLocked}
                        minLength={2}
                        maxLength={3}
                        {...register(`entryAgeFrom`, {
                          onChange: (e) => {
                            const enforcedDigits = enforceDigitsOnly(e);
                            dispatch(
                              dispatchActions.formActions.entryAgeFrom(
                                parseInt(enforcedDigits, 10),
                              ),
                            );
                          },
                        })}
                        name="entryAgeFrom"
                        isError={!!errors?.entryAgeFrom}
                      />
                    }
                    message={errors?.entryAgeFrom?.message}
                  />
                </FormRow>
              </Column>
              {/* entry Age From */}

              {/* entry Age To */}
              <Column span_xs={12} span_s={4}>
                <FormRow
                  className={styles.formRow}
                  label={REQUEST_FORM_LABELS.entryAgeTo}
                  data-testid={RequestFormFields.entryAgeTo}
                >
                  <InputErrorBox
                    input={
                      <TextField
                        disabled={isButtonLocked}
                        minLength={2}
                        maxLength={3}
                        {...register(`entryAgeTo`, {
                          onChange: (e) => {
                            const enforcedDigits = enforceDigitsOnly(e);
                            dispatch(
                              dispatchActions.formActions.entryAgeTo(
                                parseInt(enforcedDigits, 10),
                              ),
                            );
                          },
                        })}
                        name="entryAgeTo"
                        isError={!!errors?.entryAgeTo}
                      />
                    }
                    message={errors?.entryAgeTo?.message}
                  />
                </FormRow>
              </Column>
            </GridRow>
            {/* entry Age To */}

            {/* payment Methods */}
            <GridRow>
              <Column span_xs={12} span_s={4}>
                <FormRow
                  className={styles.formRow}
                  label={REQUEST_FORM_LABELS.paymentMethod}
                  data-testid={RequestFormFields.paymentMethod}
                >
                  <InputErrorBox
                    input={
                      <SelectField
                        showPlaceholder={false}
                        useEmptyOptionPlaceholder
                        disabled={isButtonLocked}
                        {...register('paymentMethod', {
                          onChange: (e) =>
                            dispatch(
                              dispatchActions.formActions.paymentMethod(
                                e.target.value,
                              ),
                            ),
                        })}
                        name="paymentMethod"
                        options={paymentMethods.map((pay) => {
                          return {
                            name: applyGermanUmlauts(pay.name),
                            value: pay.value,
                          };
                        })}
                        isError={!!errors?.paymentMethod}
                      />
                    }
                    message={errors?.paymentMethod?.message}
                  />
                </FormRow>
              </Column>
              {/* payment Methods */}
              <Column span_xs={12} span_s={4} />
              {/* intermediary Number */}
              <Column span_xs={12} span_s={4}>
                <FormRow
                  className={styles.formRow}
                  label={REQUEST_FORM_LABELS.intermediaryNumber}
                  data-testid={RequestFormFields.intermediaryNumber}
                >
                  <InputErrorBox
                    input={
                      <TextField
                        disabled={isButtonLocked}
                        {...register(`intermediaryNumber`, {
                          onChange: (e) => {
                            const enforcedDigits = enforceDigitsOnly(e);
                            dispatch(
                              dispatchActions.formActions.intermediaryNumber(
                                enforcedDigits,
                              ),
                            );
                          },
                        })}
                        name="intermediaryNumber"
                        isError={isVermittlerNumberError}
                      />
                    }
                    message={vermittlerNumberErrorMsg()}
                  />
                </FormRow>
              </Column>
            </GridRow>
            {/* intermediary Number */}

            {/* action Type */}
            <GridRow>
              <Column span_xs={12} span_s={4}>
                <FormRow
                  className={styles.formRow}
                  label={REQUEST_FORM_LABELS.actionType}
                  data-testid={RequestFormFields.actionType}
                >
                  <InputErrorBox
                    input={
                      <SelectField
                        showPlaceholder={false}
                        disabled={isButtonLocked}
                        {...register('actionType', {
                          onChange: (e) => {
                            handleClearFieldsOnActionTypeChange();
                            dispatch(
                              dispatchActions.formActions.actionType(
                                e.target.value,
                              ),
                            );
                          },
                        })}
                        name="actionType"
                        useEmptyOptionPlaceholder
                        options={createOptionsArray(
                          ActionTypes,
                          REQUEST_FORM_ACTION_TYPE_CHOICES,
                        )}
                        isError={!!errors?.actionType}
                      />
                    }
                    message={errors?.actionType?.message}
                  />
                </FormRow>
              </Column>
              {/* action Type */}

              <Column span_xs={12} span_s={4} />
              <Column span_xs={12} span_s={4}>
                <FormRow
                  className={styles.formRow}
                  label={REQUEST_FORM_LABELS.aoMailing}
                  data-testid={RequestFormFields.aoMailing}
                >
                  <InputErrorBox
                    input={
                      <Checkbox
                        disabled={isButtonLocked}
                        {...register('aoMailing', {
                          onChange: (e) => {
                            dispatch(
                              dispatchActions.formActions.aoMailing(
                                e.target.checked,
                              ),
                            );
                          },
                        })}
                        id="aoMailing"
                        name="aoMailing"
                        label=""
                      />
                    }
                    message={' '}
                  />
                </FormRow>
              </Column>
            </GridRow>

            {/* more Variants On Request */}

            {actionType !== '' && (
              <GridRow>
                <Column span_xs={12} span_s={4}>
                  <FormRow
                    className={styles.formRow}
                    label={REQUEST_FORM_LABELS.moreVariantsOnRequest}
                    data-testid={RequestFormFields.moreVariantsOnRequest}
                  >
                    <InputErrorBox
                      input={
                        <SelectField
                          showPlaceholder={false}
                          useEmptyOptionPlaceholder
                          disabled={isButtonLocked}
                          {...register('moreVariantsOnRequest', {
                            onChange: async (e) => {
                              handleClearFormFields(e);
                              dispatch(
                                dispatchActions.formActions.moreVariantsOnRequest(
                                  e.target.value,
                                ),
                              );
                            },
                          })}
                          name="moreVariantsOnRequest"
                          options={createOptionsArray(
                            MoreVariantsOnRequestChoices,
                            REQUEST_FORM_VARIANTS_ON_REQUEST_CHOICES,
                          )}
                        />
                      }
                      message={errors?.moreVariantsOnRequest?.message}
                    />
                  </FormRow>
                </Column>
                {/* more Variants On Request */}

                {/* multi Person */}
                <Column span_xs={12} span_s={4}>
                  <FormRow
                    className={styles.formRow}
                    label={REQUEST_FORM_LABELS.multiPerson}
                    data-testid={`multiPerson-${actionType}`}
                  >
                    <InputErrorBox
                      input={
                        <Checkbox
                          disabled={isButtonLocked}
                          id={RequestFormFields.multiPerson}
                          {...register(RequestFormFields.multiPerson, {
                            onChange: (e) =>
                              dispatch(
                                dispatchActions.formActions.multiPerson(
                                  e.target.checked,
                                ),
                              ),
                          })}
                          name={RequestFormFields.multiPerson}
                          label=""
                        />
                      }
                      message={' '}
                    />
                  </FormRow>
                </Column>
                <Column span_xs={12} span_s={4} />
              </GridRow>
            )}
            {/* multi Person */}

            {/* MORE VARIANTS */}
            {/* standard variant */}
            {actionType === 'Standard' &&
              formData.variantsOnRequest.variants.map((item, index) => (
                <div
                  key={(JSON.stringify(item), index)}
                  style={
                    formData.variantsOnRequest.variants.length > 1
                      ? { marginLeft: 50 }
                      : { marginLeft: 0 }
                  }
                >
                  {formData.variantsOnRequest.variants.length > 1 && (
                    <h4>{`Variante ${index + 1}`}</h4>
                  )}
                  <Grid>
                    <GridRow>
                      <Column span_xs={12} span_s={4}>
                        <FormRow
                          className={styles.formRow}
                          label={REQUEST_FORM_LABELS.denturesOffered}
                          data-testid={`variants.${index}.denturesOffered`}
                        >
                          <InputErrorBox
                            input={
                              <SelectField
                                disabled={isButtonLocked}
                                {...register(
                                  `variants.${index}.denturesOffered`,
                                  {
                                    onChange: (e) => {
                                      trigger([
                                        `variants.${index}.toothPreservationOffered`,
                                      ]);
                                      dispatch(
                                        dispatchActions.formActions.variants(
                                          `variants.${index}.denturesOffered`,
                                          e.target.value,
                                        ),
                                      );
                                    },
                                  },
                                )}
                                name={`variants.${index}.denturesOffered`}
                                useEmptyOptionPlaceholder
                                options={createOptionsArray(
                                  DenturesOfferedChoices,
                                  REQUEST_FORM_DENTURES_OFFERED_CHOICES,
                                )}
                              />
                            }
                            message={
                              errors?.variants?.[index]?.denturesOffered
                                ?.message
                            }
                          />
                        </FormRow>
                      </Column>

                      <Column span_xs={12} span_s={4}>
                        <FormRow
                          className={styles.formRow}
                          label={REQUEST_FORM_LABELS.toothPreservationOffered}
                          data-testid={`variants.${index}.toothPreservationOffered`}
                        >
                          <InputErrorBox
                            input={
                              <SelectField
                                disabled={isButtonLocked}
                                {...register(
                                  `variants.${index}.toothPreservationOffered`,
                                  {
                                    onChange: (e) => {
                                      trigger([
                                        `variants.${index}.denturesOffered`,
                                      ]);
                                      dispatch(
                                        dispatchActions.formActions.variants(
                                          `variants.${index}.toothPreservationOffered`,
                                          e.target.value,
                                        ),
                                      );
                                    },
                                  },
                                )}
                                useEmptyOptionPlaceholder
                                options={createOptionsArray(
                                  ToothPreservationOfferedChoices,
                                  REQUEST_FORM_TOOTH_PRESERVATION_OFFERED_CHOICES,
                                )}
                              />
                            }
                            message={
                              errors?.variants?.[index]
                                ?.toothPreservationOffered?.message
                            }
                          />
                        </FormRow>
                      </Column>
                    </GridRow>
                  </Grid>
                </div>
              ))}

            {/* tarifwechsel && upselling variant */}
            {actionType !== 'Standard' &&
              formData.variantsOnRequest.variants.map((item, index) => (
                <div
                  key={(JSON.stringify(item), index)}
                  style={
                    formData.variantsOnRequest.variants.length > 1
                      ? { marginLeft: 50 }
                      : { marginLeft: 0 }
                  }
                >
                  {formData.variantsOnRequest.variants.length > 1 && (
                    <h4>{`Variante ${index + 1}`}</h4>
                  )}
                  <Grid>
                    <GridRow>
                      <Column span_xs={12} span_s={4}>
                        <FormRow
                          className={styles.formRow}
                          label={
                            actionType === 'Tarifwechsel'
                              ? REQUEST_FORM_LABELS.changeFrom
                              : REQUEST_FORM_LABELS.BaseRate
                          }
                          data-testid={`variants.${index}.changeFrom`}
                        >
                          <InputErrorBox
                            input={
                              <SelectField
                                disabled={isButtonLocked}
                                {...register(`variants.${index}.changeFrom`, {
                                  onChange: (e) =>
                                    dispatch(
                                      dispatchActions.formActions.variants(
                                        `variants.${index}.changeFrom`,
                                        e.target.value,
                                      ),
                                    ),
                                })}
                                name={`variants.${index}.changeFrom`}
                                useEmptyOptionPlaceholder
                                options={
                                  actionType === 'Tarifwechsel'
                                    ? createOptionsArray(
                                        ChangeFrom,
                                        REQUEST_FORM_CHANGE_FROM_CHOICES,
                                      )
                                    : createOptionsArray(
                                        BaseRate,
                                        REQUEST_FORM_UPSELLING_BASE_RATE_CHOICES,
                                      )
                                }
                              />
                            }
                            message={
                              errors?.variants?.[index]?.changeFrom?.message
                            }
                          />
                        </FormRow>
                      </Column>

                      <Column span_xs={12} span_s={4}>
                        <FormRow
                          className={styles.formRow}
                          label={
                            actionType === 'Tarifwechsel'
                              ? REQUEST_FORM_LABELS.changeTo
                              : REQUEST_FORM_LABELS.ConstrictionTariffs
                          }
                          data-testid={`variants.${index}.changeTo`}
                        >
                          <InputErrorBox
                            input={
                              <SelectField
                                disabled={isButtonLocked}
                                {...register(`variants.${index}.changeTo`, {
                                  onChange: (e) =>
                                    dispatch(
                                      dispatchActions.formActions.variants(
                                        `variants.${index}.changeTo`,
                                        e.target.value,
                                      ),
                                    ),
                                })}
                                name={`variants.${index}.changeTo`}
                                useEmptyOptionPlaceholder
                                options={
                                  actionType === 'Tarifwechsel'
                                    ? createOptionsArray(
                                        ChangeTo,
                                        REQUEST_FORM_CHANGE_TO_CHOICES,
                                      )
                                    : createOptionsArray(
                                        ConstrictionTariffs,
                                        REQUEST_FORM_UPSELLING_CONSTRICTION_TARIFFS_CHOICES,
                                      )
                                }
                              />
                            }
                            message={
                              errors?.variants?.[index]?.changeTo?.message
                            }
                          />
                        </FormRow>
                      </Column>
                    </GridRow>
                  </Grid>
                </div>
              ))}

            {/* those two fields are rendered on tariffwechsel && upselling action type */}
            {(actionType === 'Tarifwechsel' || actionType === 'Upselling') && (
              <Grid>
                <GridRow>
                  <Column span_xs={12} span_s={4}>
                    <FormRow
                      className={styles.formRow}
                      label={REQUEST_FORM_LABELS.additionalTeethPreservation}
                      data-testid={
                        RequestFormFields.additionalToothPreservation
                      }
                    >
                      <InputErrorBox
                        input={
                          <SelectField
                            disabled={isButtonLocked}
                            {...register('additionalTeethPreservation', {
                              onChange: (e) =>
                                dispatch(
                                  dispatchActions.formActions.additionalToothPreservation(
                                    'additionalTeethPreservation',
                                    e.target.value,
                                  ),
                                ),
                            })}
                            name="additionalTeethPreservation"
                            useEmptyOptionPlaceholder
                            options={
                              actionType === 'Tarifwechsel'
                                ? createOptionsArray(
                                    AdditionalToothPreservation,
                                    REQUEST_FORM_ADDITIONAL_TOOTH_PRESERVATION_CHOICES,
                                  )
                                : createOptionsArray(
                                    UpsellingAdditionalToothPreservation,
                                    REQUEST_FORM_UPSELLING_ADDITIONAL_TOOTH_PRESERVATION_CHOICES,
                                  )
                            }
                          />
                        }
                        message={errors?.additionalToothPreservation?.message}
                      />
                    </FormRow>
                  </Column>

                  <Column span_xs={12} span_s={4}>
                    <FormRow
                      className={styles.formRow}
                      label={REQUEST_FORM_LABELS.additionalDentures}
                      data-testid={RequestFormFields.additionalDentures}
                    >
                      <InputErrorBox
                        input={
                          <SelectField
                            disabled={isButtonLocked}
                            {...register('additionalDentures', {
                              onChange: (e) =>
                                dispatch(
                                  dispatchActions.formActions.additionalDentures(
                                    'additionalDentures',
                                    e.target.value,
                                  ),
                                ),
                            })}
                            name="additionalDentures"
                            useEmptyOptionPlaceholder
                            options={
                              actionType === 'Tarifwechsel'
                                ? createOptionsArray(
                                    AdditionalDentures,
                                    REQUEST_FORM_ADDITIONAL_DENTURES_CHOICES,
                                  )
                                : createOptionsArray(
                                    UpsellingAdditionalDentures,
                                    REQUEST_FORM_UPSELLING_ADDITIONAL_DENTURES_CHOICES,
                                  )
                            }
                          />
                        }
                        message={errors?.additionalDentures?.message}
                      />
                    </FormRow>
                  </Column>
                </GridRow>
              </Grid>
            )}

            {/* Submit Form  */}
            <DimmedSection>
              <SubmitButton disabled={isButtonLocked} />
            </DimmedSection>
          </Box>
        </form>
      </FormSection>
    </div>
  );
};
