import { combineReducers } from 'redux';
import userInterfaceReducer from './user-interface-reducer';
import requestsReducer from './requests-reducer';
import formReducer from './form-reducer';

const reducer = combineReducers({
  userInterface: userInterfaceReducer,
  requests: requestsReducer,
  formData: formReducer,
});

export default reducer;
