import LoadingSpinner from '@eg/elements/LoadingSpinner';
import logo from 'assets/svgs/ergo_logo.svg';
import { useSelector } from 'react-redux';
import { StoreProps } from 'types/app.types';
import styles from './Loader.module.scss';

export const Loader = () => {
  const uiState = useSelector((state: StoreProps) => state.userInterface);
  const { loaderState } = uiState;

  return (
    <div
      data-testid="loader"
      className={styles.root}
      style={{
        display: loaderState.show ? 'flex' : 'none',
      }}
    >
      <LoadingSpinner viewport="relative" show />
      <div className={styles.logoBox}>
        <img className={styles.imageSize} src={logo} alt="ergo-logo" />
      </div>
      <div className={styles.infoLabel}>{loaderState.infoLabel}</div>
    </div>
  );
};
