import { RequestFormErrorMessages } from 'types';
import * as Yup from 'yup';
import { formValidationSchemaCommon } from './form-validation-schema-common';

export const formValidationSchemaStandard = Yup.object().shape({
  ...formValidationSchemaCommon,
  variants: Yup.array().of(
    Yup.object().shape(
      {
        toothPreservationOffered: Yup.string()
          .ensure()
          .when('denturesOffered', (denturesOffered) => {
            if (denturesOffered === '')
              return Yup.string().required(RequestFormErrorMessages.tariff);
            return Yup.string().notRequired();
          }),
        denturesOffered: Yup.string().when(
          'toothPreservationOffered',
          (toothPreservationOffered) => {
            if (toothPreservationOffered === '')
              return Yup.string().required(RequestFormErrorMessages.tariff);
            return Yup.string().notRequired();
          },
        ),
      },
      [['toothPreservationOffered', 'denturesOffered']],
    ),
  ),
});
