import axios from 'axios';
import { CONFIG_BACKEND_URL } from 'config';

const local = CONFIG_BACKEND_URL;
const api = axios.create({
  withCredentials: true,
  baseURL: local,
});

export default api;
