import { FormSelectOptions } from 'types';

export const createOptionsArray = (
  values: Record<string, string>,
  names: Record<string, string>,
): FormSelectOptions[] =>
  Object.keys(values).map((option) => ({
    name: names[option as keyof typeof names],
    value: values[option as keyof typeof values],
  }));
