import { dispatchActions } from 'store/actions/dispatch-actions';
import { redirectToLoginPage } from './redirect-to-login-page-call';
import { GetDataFromInitialOfferProps } from './api-calls.types';
import { RequestService } from '../routes';

export const getDataFromInitialOffer = ({
  dispatch,
}: GetDataFromInitialOfferProps) => {
  dispatch(dispatchActions.userInterfaceAction.appInitialize);
  dispatch(dispatchActions.userInterfaceAction.userAuthorized);
  RequestService.initialOffer()
    .then((response) => {
      const insuranceStartDates = response.data?.insuranceStartDates;
      const paymentMethodsFromOffer = response.data?.paymentMethods;
      dispatch(
        dispatchActions.requestActions.insuranceStartDates(insuranceStartDates),
      );
      dispatch(
        dispatchActions.requestActions.paymentMethods(paymentMethodsFromOffer),
      );
      dispatch(dispatchActions.userInterfaceAction.loader.clear);
    })
    .catch((error) => {
      if (error.response.data.statusCode === 400) {
        dispatch(dispatchActions.userInterfaceAction.loader.networkError);
      }
      if (error.response.data.statusCode === 401) {
        redirectToLoginPage();
      }
      if (error.response.data.statusCode === 500) {
        dispatch(dispatchActions.userInterfaceAction.loader.networkError);
      }
    });
};
