import {
  SET_IS_APP_INITIALIZED,
  SET_IS_DOWNLOAD_BUTTON_LOCKED,
  SET_LOADER_STATE,
  SET_USER_AUTHORIZED,
} from 'store/actions';
import { UserInterfaceInitialState } from 'store/constants/initial-state';
import { ActionTypeProps } from '../actions/actions-type.types';

export const initialState = UserInterfaceInitialState;

const userInterfaceReducer = (
  state = initialState,
  action: ActionTypeProps,
) => {
  switch (action.type) {
    case SET_IS_APP_INITIALIZED:
      return { ...state, isAppInitialized: action.payload };

    case SET_LOADER_STATE:
      return {
        ...state,
        loaderState: {
          show: action.payload.show,
          infoLabel: action.payload.infoLabel,
        },
      };
    case SET_USER_AUTHORIZED:
      return { ...state, isUserAuthorized: action.payload };

    case SET_IS_DOWNLOAD_BUTTON_LOCKED:
      return { ...state, isButtonLocked: action.payload };

    default:
      return state;
  }
};

export default userInterfaceReducer;
