// request actions
export const SET_POST_RESPONSE = '@SET_POST_RESPONSE';
export const SET_POST_ERRORS_RESPONSE = '@SET_POST_ERRORS_RESPONSE';
export const SET_INITIAL_STATE = '@SET_INITIAL_STATE';
export const SET_API_ERRORS = '@SET_API_ERRORS';
export const SET_BACKEND_ERRORS = '@SET_BACKEND_ERRORS';
export const SET_PAYLOAD_VALUES = '@SET_PAYLOAD_VALUES';
export const SET_IS_API_ERRORS = '@SET_IS_API_ERRORS';
export const SET_INSURANCE_START_DATES_RANGE =
  '@SET_INSURANCE_START_DATES_RANGE';
export const SET_PAYMENT_METHODS = '@SET_PAYMENT_METHODS';

// user interface actions
export const SET_IS_APP_INITIALIZED = '@SET_IS_APP_INITIALIZED';
export const SET_USER_AUTHORIZED = '@SET_USER_AUTHORIZED';
export const SET_LOADER_STATE = '@SET_LOADER_STATE';
export const SET_IS_DOWNLOAD_BUTTON_LOCKED = '@SET_IS_DOWNLOAD_BUTTON_LOCKED';

// form actions
export const SET_AO_MAILING = '@SET_AO_MAILING_VALUE';
export const SET_MULTIPERSON = '@SET_MULTIPERSON_VALUE';
export const SET_CONCEPT_NAME = '@SET_conceptName';
export const SET_ACTION_NUMBER = '@SET_actionNumber';
export const SET_INSURANCE_START_DATE = '@SET_insuranceStartDate';
export const SET_ENTRTY_AGE_FROM = '@SET_entryAgeFrom';
export const SET_ENTRTY_AGE_TO = '@SET_entryAgeTo';
export const SET_PAYMENT_METHOD = '@SET_paymentMethod';
export const SET_INTERMEDIARY_NUMBER = '@SET_intermediaryNumber';
export const SET_ACTION_TYPE = '@SET_actionType';
export const SET_MORE_VARIANTS_ON_REQUEST = '@SET_moreVariantsOnRequest';
export const SET_VARIANTS_ON_REQUEST = '@SET_variantsOnRequest';
export const SET_VARIANTS = '@SET_variants';
export const SET_ADDITIONAL_TOOTH_PRESERVATION =
  '@SET_additionalToothPreservation';
export const SET_ADDITIONAL_DENTURES = '@SET_additionalDentures';
export const CLEAR_VARIANTS_ARRAY = '@CLEAR_VARIANTS_ARRAY';
