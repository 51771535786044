import { findErrorMessage } from 'helpers';
import { dispatchActions } from 'store/actions/dispatch-actions';
import { RequestService } from '../routes';
import { FinalOfferProps } from './api-calls.types';
import { downloadFile } from './download-file-call';

export const finalOffer = ({ data, dispatch, setError }: FinalOfferProps) => {
  dispatch(dispatchActions.userInterfaceAction.loader.sendData);
  RequestService.finalOffer(data)
    .then((response) => {
      const bid = response.data;
      dispatch(dispatchActions.requestActions.noApiErrors);
      dispatch(dispatchActions.userInterfaceAction.loader.fileIsDownloading);
      dispatch(dispatchActions.userInterfaceAction.lockAllInputs);
      downloadFile({ bid });
    })
    .catch((error) => {
      if (error.response.data.statusCode === 400) {
        dispatch(
          dispatchActions.requestActions.backEndErrors(
            error.response.data.errors,
          ),
        );
        error.response.data.errors.forEach((fieldName: string) =>
          setError(fieldName, {
            message: findErrorMessage(fieldName),
          }),
        );
      }
      if (
        error.response.data.messages[0].toLowerCase.includes('vermittlernummer')
      ) {
        dispatch(dispatchActions.requestActions.thereAreApiErrors);
        dispatch(
          dispatchActions.requestActions.ergoApiErrors(error.response.data),
        );
      }
      if (error.response.data.messages[0].includes('vermittlernummer')) {
        dispatch(dispatchActions.requestActions.thereAreApiErrors);
        dispatch(
          dispatchActions.requestActions.ergoApiErrors(error.response.data),
        );
      }
    })
    .finally(() => {
      dispatch(dispatchActions.userInterfaceAction.loader.clear);
    });
};
