import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OFFLINE_DEVELOPMENT, uiPath } from 'config';
import { getDataFromInitialOffer } from 'services/api/api-calls/initial-offer-call';
import { Provider } from '@eg/elements/Provider';
import { Loader } from 'components/Loader/Loader';
import { RefreshUserToken } from 'components/RefreshUserToken';
import { AuthorizedRoutes, UnauthorizedRoutes } from 'components/Router';
import { StoreProps } from 'types/app.types';
import { useNavigate } from 'react-router-dom';
import { dispatchActions } from 'store/actions/dispatch-actions';
import styles from './App.module.scss';

export const App: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uiState = useSelector((state: StoreProps) => state.userInterface);
  const { isAppInitialized, isUserAuthorized } = uiState;
  const unauthorizedUser = () => {
    dispatch(dispatchActions.userInterfaceAction.loader.clear);
  };

  useEffect(() => {
    if (window.location.pathname !== uiPath.unauthorized) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      OFFLINE_DEVELOPMENT
        ? (dispatch(dispatchActions.userInterfaceAction.appInitialize),
          dispatch(dispatchActions.userInterfaceAction.userAuthorized),
          dispatch(dispatchActions.userInterfaceAction.loader.clear))
        : getDataFromInitialOffer({ dispatch });
      navigate(uiPath.request);
    } else if (!isUserAuthorized) {
      unauthorizedUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Provider data-testid="provider" className={styles.root} theme="ergo-one">
      <Loader data-testid="loader" />
      {!isUserAuthorized && (
        <UnauthorizedRoutes data-testid="unauthorizedRoutes" />
      )}
      {isAppInitialized && isUserAuthorized && (
        <>
          <AuthorizedRoutes data-testid="authorizedRoutes" />
          <RefreshUserToken />
        </>
      )}
    </Provider>
  );
};
