import { RequestFormErrorMessages } from 'types';
import * as Yup from 'yup';

export const formValidationSchemaCommon = {
  moreVariantsOnRequest: Yup.number()
    .moreThan(0, RequestFormErrorMessages.moreVariantsOnRequest)
    .required(RequestFormErrorMessages.moreVariantsOnRequest),
  conceptName: Yup.string()
    .min(1, RequestFormErrorMessages.conceptName)
    .max(150, RequestFormErrorMessages.conceptName)
    .required(RequestFormErrorMessages.conceptName),
  paymentMethod: Yup.string().required(RequestFormErrorMessages.paymentMethod),
  insuranceStartDate: Yup.string().required(
    RequestFormErrorMessages.insuranceStartDate,
  ),
  actionNumber: Yup.string()
    .min(4, RequestFormErrorMessages.actionNumber)
    .max(4, RequestFormErrorMessages.actionNumber)
    .required(),
  actionType: Yup.string().required(RequestFormErrorMessages.actionType),
  entryAgeFrom: Yup.number()
    .typeError(RequestFormErrorMessages.entryAgeFromReq)
    .required(RequestFormErrorMessages.entryAgeFromReq)
    .min(18, RequestFormErrorMessages.entryAgeFromMin)
    .max(120, RequestFormErrorMessages.entryAgeFromMax),
  entryAgeTo: Yup.string().when('entryAgeFrom', (entryAgeFrom) => {
    return Yup.number()
      .typeError(RequestFormErrorMessages.entryAgeToReq)
      .required(RequestFormErrorMessages.entryAgeFromReq)
      .min(entryAgeFrom, RequestFormErrorMessages.entryAgeToMin)
      .max(120, RequestFormErrorMessages.entryAgeToMax);
  }),
};
