import { RequestPage } from 'components/RequestPage';
import { uiPath } from 'config';
import { Routes, Route } from 'react-router-dom';

export const AuthorizedRoutes = () => {
  return (
    <Routes data-testid="authorizedRoutes">
      <Route path={uiPath.request} element={<RequestPage />} />
    </Routes>
  );
};
