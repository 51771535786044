import { ForwardedRef, forwardRef } from 'react';
import { SelectFieldSvg } from './SelectField.svg';
import { SelectFieldProps } from './SelectField.types';

export const SelectField = forwardRef(
  (
    {
      isError,
      name,
      options,
      onClick,
      useEmptyOptionPlaceholder,
      showPlaceholder = true,
      disabled,
      ...formProps
    }: SelectFieldProps,
    ref: ForwardedRef<HTMLSelectElement>,
  ) => {
    return (
      <div className="ee_select">
        <select
          disabled={disabled}
          onClick={onClick}
          className="ee_select__control"
          ref={ref}
          name={name}
          {...(isError && { 'aria-invalid': true })}
          {...formProps}
        >
          {useEmptyOptionPlaceholder && (
            <option
              value=""
              style={{
                display: showPlaceholder ? 'block' : 'none',
              }}
            >
              {/* Empty placeholder */}
            </option>
          )}
          {options?.map((option, index) => (
            <option value={option.value} key={`${option.value}_${index + 1}`}>
              {option.name}
            </option>
          ))}
        </select>
        <SelectFieldSvg />
      </div>
    );
  },
);
