import * as actions from 'store/actions';

export const formActions = {
  conceptName: (value: string) => {
    return { type: actions.SET_CONCEPT_NAME, payload: value };
  },
  actionNumber: (value: string) => {
    return { type: actions.SET_ACTION_NUMBER, payload: value };
  },
  insuranceStartDate: (value: string) => {
    return { type: actions.SET_INSURANCE_START_DATE, payload: value };
  },
  entryAgeFrom: (value: number) => {
    return { type: actions.SET_ENTRTY_AGE_FROM, payload: value };
  },
  entryAgeTo: (value: number) => {
    return { type: actions.SET_ENTRTY_AGE_TO, payload: value };
  },
  paymentMethod: (value: string) => {
    return { type: actions.SET_PAYMENT_METHOD, payload: value };
  },
  intermediaryNumber: (value: string) => {
    return { type: actions.SET_INTERMEDIARY_NUMBER, payload: value };
  },
  aoMailing: (value: boolean) => {
    return { type: actions.SET_AO_MAILING, payload: value };
  },
  actionType: (value: string) => {
    return { type: actions.SET_ACTION_TYPE, payload: value };
  },
  moreVariantsOnRequest: (value: string) => {
    return { type: actions.SET_MORE_VARIANTS_ON_REQUEST, payload: value };
  },
  variantsOnRequest: (value: string) => {
    return { type: actions.SET_VARIANTS_ON_REQUEST, payload: value };
  },
  variants: (id: string, value: string) => {
    return {
      type: actions.SET_VARIANTS,
      payload: { [id]: value },
    };
  },
  additionalToothPreservation: (id: string, value: string) => {
    return {
      type: actions.SET_ADDITIONAL_TOOTH_PRESERVATION,
      payload: { [id]: value },
    };
  },
  additionalDentures: (id: string, value: string) => {
    return {
      type: actions.SET_ADDITIONAL_DENTURES,
      payload: { [id]: value },
    };
  },
  multiPerson: (value: boolean) => {
    return { type: actions.SET_MULTIPERSON, payload: value };
  },
  clearVariantsArray: () => {
    return { type: actions.CLEAR_VARIANTS_ARRAY };
  },
};
