import * as actions from 'store/actions';
import { RequestsInitialState } from 'store/constants/initial-state';
import { ActionTypeProps } from '../actions/actions-type.types';

export const initialState = RequestsInitialState;

const requestsReducer = (state = initialState, action: ActionTypeProps) => {
  switch (action.type) {
    case actions.SET_INITIAL_STATE:
      return { ...state, initialState: action.payload };

    case actions.SET_INSURANCE_START_DATES_RANGE:
      return { ...state, insuranceStartDateRange: action.payload };

    case actions.SET_PAYMENT_METHODS:
      return { ...state, paymentMethods: action.payload };

    case actions.SET_API_ERRORS:
      return { ...state, apiErrors: action.payload?.messages };

    case actions.SET_BACKEND_ERRORS:
      return { ...state, backendErrors: action.payload };

    case actions.SET_IS_API_ERRORS:
      return { ...state, isApiErrors: action.payload };

    default:
      return state;
  }
};

export default requestsReducer;
