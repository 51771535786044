export enum Endpoints {
  requestValidation,
  initialOffer,
  fileDownload,
  login,
  refreshToken,
}

export enum RequestFormFields {
  conceptName = 'conceptName', // Name des Konzeptes
  actionNumber = 'actionNumber', // Aktionsnr.
  insuranceStartDate = 'insuranceStartDate', // Versicherungsbeginn
  entryAgeFrom = 'entryAgeFrom', // Entrittsalter von
  entryAgeTo = 'entryAgeTo', // Eintrittsalter bis
  paymentMethod = 'paymentMethod', // Zahlweise
  intermediaryNumber = 'intermediaryNumber', // Vermittlernummer
  aoMailing = 'aoMailing', // AO Mailing
  actionType = 'actionType', // Maßnahmenart
  moreVariantsOnRequest = 'moreVariantsOnRequest', // Mehrere Varianten auf Antrag?
  variantsOnRequest = 'variantsOnRequest', // array of chosen variants
  multiPerson = 'multiPerson', // Mehrpersonen
  additionalToothPreservation = 'additionalToothPreservation',
  additionalDentures = 'additionalDentures',
}

/* eslint-disable no-template-curly-in-string */
export enum RequestFormDynamicFields {
  toothPreservationOffered = 'variantsOnRequest.${number}.toothPreservationOffered', // Angebotener Zahnerhalt
  denturesOffered = 'variantsOnRequest.${number}.denturesOffered', // Angebotener Zahnersatz
  comboOfBoth = 'variantsOnRequest.${number}.comboOfBoth', // Kombi aus beiden?
  changeFrom = 'variantsOnRequest.${number}.changeFrom', // Wechsel von
  changeTo = 'variantsOnRequest.${number}.changeTo', // Wechsel in
  // baseRate = 'variantsOnRequest.${number}.baseRate', // Bestandstarif
  // constrictionTariffs = 'variantsOnRequest.${number}.constrictionTariffs', // Ergänzungstarif/e
}
/* eslint-enable no-template-curly-in-string */

export enum MoreVariantsOnRequestChoices {
  none = '1',
  two = '2',
  three = '3',
}

export enum VariantChoiceValues {
  empty = 0,
  none = 1,
  two = 2,
  three = 3,
}

export enum PaymentMethodChoices {
  monthly = 'Monatlich',
  quarterly = 'Vierteljährlich',
  halfYearly = 'Halbjährlich',
  yearly = 'Jährlich',
}

export enum ActionTypes {
  standard = 'Standard',
  tarifweschel = 'Tarifwechsel',
  upselling = 'Upselling',
}

export enum ToothPreservationOfferedChoices {
  ZBB = 'ZBB',
  ZBB_ZBE = 'ZBB_ZBE',
  DVB = 'DVB',
  DVB_DVE = 'DVB_DVE',
}

export enum DenturesOfferedChoices {
  ZEF = 'ZEF',
  ZZP = 'ZZP',
  ZAB = 'ZAB',
  ZAB_ZAE = 'ZAB_ZAE',
  DS75 = 'DS75',
  DS75_DS90 = 'DS75_DS90',
  DS75_DS90_DS100 = 'DS75_DS90_DS100',
}

// massnahmenart tarifWechsel
// Wechsel von
export enum ChangeFrom {
  ZEE = 'ZEE',
  ZEF = 'ZEF',
  ZZP = 'ZZP',
  ZAB = 'ZAB',
  ZAB_ZAE = 'ZAB_ZAE',
  ZBB = 'ZBB',
  ZBB_ZBE = 'ZBB_ZBE',
  ZAB_ZAE_ZBB_ZBE = 'ZAB_ZAE_ZBB_ZBE',
}

// Wechsel in
export enum ChangeTo {
  ZAB = 'ZAB',
  ZAB_ZAE = 'ZAB_ZAE',
  DS75 = 'DS75',
  DS75_DS90 = 'DS75_DS90',
  DS75_DS90_DS100 = 'DS75_DS90_DS100',
  DVB = 'DVB',
  DVB_DVE = 'DVB_DVE',
  DS75_DS90_DVB = 'DS75_DS90_DVB',
  DS75_DS90_DVB_DVE = 'DS75_DS90_DVB_DVE',
  DS75_DS90_DS100_DVB = 'DS75_DS90_DS100_DVB',
  DS75_DS90_DS100_DVB_DVE = 'DS75_DS90_DS100_DVB_DVE',
}

// zusatzlicher zahnerhalt
export enum AdditionalToothPreservation {
  DVB = 'DVB',
  DVE = 'DVE',
  DVB_DVE = 'DVB_DVE',
  ZBB = 'ZBB',
  ZBE = 'ZBE',
  ZBB_ZBE = 'ZBB_ZBE',
}

// zusatzlicher zahnersatz
export enum AdditionalDentures {
  ZEF = 'ZEF',
  ZZP = 'ZZP',
  ZAB = 'ZAB',
  ZAB_ZAE = 'ZAB_ZAE',
  DS75 = 'DS75',
  DS75_DS90 = 'DS75_DS90',
  DS75_DS90_DS100 = 'DS75_DS90_DS100',
}

// Upselling
export enum BaseRate {
  ZEG = 'ZEG',
  ZEF = 'ZEF',
  ZZP = 'ZZP',
  ZAB = 'ZAB',
  DS75 = 'DS75',
  ZES = 'ZES',
  ZBB = 'ZBB',
  DVB = 'DVB',
}

export enum ConstrictionTariffs {
  ZAB = 'ZAB',
  ZAE = 'ZAE',
  ZAB_ZAE = 'ZAB_ZAE',
  ZEF = 'ZEF',
  ZZP = 'ZZP',
  DS75 = 'DS75',
  DS90 = 'DS90',
  DS100 = 'DS100',
  DS75_DS90 = 'DS75_DS90',
  DS75_DS90_DS100 = 'DS75_DS90_DS100',
  DS90_DS100 = 'DS90_DS100',
  ZBB = 'ZBB',
  ZBE = 'ZBE',
  ZBB_ZBE = 'ZBB_ZBE',
  DVB = 'DVB',
  DVE = 'DVE',
  DVB_DVE = 'DVB_DVE',
}

export enum UpsellingAdditionalToothPreservation {
  ZBB = 'ZBB',
  ZBE = 'ZBE',
  ZBB_ZBE = 'ZBB_ZBE',
  DVB = 'DVB',
  DVE = 'DVE',
  DVB_DVE = 'DVB_DVE',
}

export enum UpsellingAdditionalDentures {
  ZAB = 'ZAB',
  ZAE = 'ZAE',
  ZAB_ZAE = 'ZAB_ZAE',
  ZEF = 'ZEF',
  ZZP = 'ZZP',
  DS75 = 'DS75',
  DS90 = 'DS90',
  DS100 = 'DS100',
  DS75_DS90 = 'DS75_DS90',
  DS75_DS90_DS100 = 'DS75_DS90_DS100',
  DS90_DS100 = 'DS90_DS100',
}

export type RequestFormValues = {
  [RequestFormFields.conceptName]: string;
  [RequestFormFields.actionNumber]: string;
  [RequestFormFields.insuranceStartDate]: string;
  [RequestFormFields.entryAgeFrom]: string;
  [RequestFormFields.entryAgeTo]: string;
  [RequestFormFields.paymentMethod]: PaymentMethodChoices | '';
  [RequestFormFields.intermediaryNumber]: string;
  [RequestFormFields.aoMailing]: boolean;
  [RequestFormFields.actionType]: ActionTypes | '';
  [RequestFormFields.moreVariantsOnRequest]: MoreVariantsOnRequestChoices | '';
  [RequestFormFields.multiPerson]: boolean;
  [RequestFormFields.variantsOnRequest]: VariantsOnRequest[];
  [RequestFormFields.additionalToothPreservation]:
    | AdditionalToothPreservation
    | UpsellingAdditionalToothPreservation;
  [RequestFormFields.additionalDentures]:
    | AdditionalDentures
    | UpsellingAdditionalDentures;
};

export type RequestFormDynamicValues = {
  [RequestFormDynamicFields.toothPreservationOffered]:
    | ToothPreservationOfferedChoices
    | '';
  [RequestFormDynamicFields.denturesOffered]: DenturesOfferedChoices | '';
  [RequestFormDynamicFields.comboOfBoth]: boolean | undefined;
  [RequestFormDynamicFields.changeFrom]: ChangeFrom | '';
  [RequestFormDynamicFields.changeTo]: ChangeTo | '';
  // [RequestFormDynamicFields.baseRate]: BaseRate | '';
  // [RequestFormDynamicFields.constrictionTariffs]: ConstrictionTariffs | '';
};

export type VariantsOnRequest = {
  toothPreservationOffered: ToothPreservationOfferedChoices | '';
  denturesOffered: DenturesOfferedChoices | '';
  comboOfBoth: boolean;
  changeFrom: ChangeFrom | '';
  changeTo: ChangeTo | '';
  baseRate: BaseRate | '';
  constrictionTariffs: ConstrictionTariffs | '';
};

export type AdditionalFields = {
  additionalToothPreservation:
    | AdditionalToothPreservation
    | UpsellingAdditionalToothPreservation;
  additionalDentures: AdditionalDentures | UpsellingAdditionalDentures;
};

export enum LoginFormFields {
  username = 'username',
  password = 'password',
}

export type LoginFormValues = {
  [LoginFormFields.username]: string;
  [LoginFormFields.password]: string;
};

export type FormSelectOptions = {
  name: string;
  value: string;
};

export type Noop = () => void;

export const noop: Noop = () => {};

// validation
export enum RequestFormErrorMessages {
  conceptName = 'Maximale Anzahl an Zeichen erreicht.', // Name des Konzeptes
  actionNumber = 'Die ANR hat maximal 4 Zahlen.', // Aktionsnr.
  insuranceStartDate = 'Bitte geben Sie ein gültiges Datum im Format TT.MM.JJJJ ein.', // Versicherungsbeginn
  entryAgeFromMin = 'Der Versicherungsnehmer darf nicht jünger als 18 sein.', // Eintrittsalter von
  entryAgeFromMax = 'Der Versicherungsnehmer darf nicht älter als 120 Jahre sein.', // Eintrittsalter von
  entryAgeFromReq = 'Bitte geben Sie das Alter des Versicherungsnehmers ein', // Eintrittsalter von
  entryAgeToMin = 'Bitte geben Sie ein gültiges Endalter ein.', // Eintrittsalter bis
  entryAgeToMax = 'Bitte geben Sie ein gültiges Endalter ein.', // Eintrittsalter bis
  entryAgeToReq = 'Bitte geben Sie ein gültiges Endalter ein.', // Eintrittsalter bis
  paymentMethod = 'Bitte wählen Sie eine Zahlweise.', // Zahlweise
  intermediaryNumber = 'Bitte geben Sie eine gültige Vermittlernummer ein.', // Vermittlernummer
  aoMailing = '', // AO Mailing
  actionType = 'Bitte wählen Sie eine Maßnahmenart.', // Maßnahmenart
  moreVariantsOnRequest = 'Bitte wählen Sie die gewünschte Anzahl der Varianten aus.', // Mehrere Varianten auf Antrag
  multiPerson = '', // Mehrpersonen
  tariff = 'Bitte wählen Sie eine Möglichkeit aus der Liste.', // Mehrere Varianten auf Antrag?
  additionalToothPreservation = 'Bitte wählen Sie eine Möglichkeit aus der Liste.', // Zusatzlich Zahnerhalt
  additionalDentures = 'Bitte wählen Sie eine Möglichkeit aus der Liste.', // Zusatzlich Zahnersatz
  downloadButton = 'Entschuldigen Sie, da ist etwas schief gegangen. Bitte wenden Sie sich an die Hotline.', // Download
  'agency.vermittlernummer.is.invalid' = 'The vermittlernummer may only contain numbers (0-9) and has a minimum length of 6 digits.',
  'agency.vermittlernummer.is.unknown' = 'Der Vermittler konnte nicht anhand der Vermittlernummer ermittelt werden.',
  vermittlernummer = 'Der Vermittler konnte nicht anhand der Vermittlernummer ermittelt werden.',
  invalidPattern = 'Das eingegebene Format ist ungültig',
}

export type RequestFormError = {
  [RequestFormErrorMessages.conceptName]: string;
  [RequestFormErrorMessages.actionNumber]: string;
  [RequestFormErrorMessages.insuranceStartDate]: string;
  [RequestFormErrorMessages.entryAgeFromMin]: string;
  [RequestFormErrorMessages.entryAgeFromMax]: string;
  [RequestFormErrorMessages.entryAgeFromReq]: string;
  [RequestFormErrorMessages.entryAgeToMin]: string;
  [RequestFormErrorMessages.entryAgeToMax]: string;
  [RequestFormErrorMessages.entryAgeToReq]: string;
  [RequestFormErrorMessages.paymentMethod]: string;
  [RequestFormErrorMessages.intermediaryNumber]: string;
  [RequestFormErrorMessages.aoMailing]: string;
  [RequestFormErrorMessages.actionType]: string;
  [RequestFormErrorMessages.moreVariantsOnRequest]: string;
  [RequestFormErrorMessages.multiPerson]: string;
  [RequestFormErrorMessages.tariff]: string;
  [RequestFormErrorMessages.downloadButton]: string;
};

export type CollectedErrors = [
  { conceptName: string },
  { actionNumber: string },
  { insuranceStartDate: string },
  { entryAgeFrom: string },
  { entryAgeTo: string },
  { paymentMethod: string },
  { intermediaryNumber: string },
  { aoMailing: boolean },
  { actionType: string },
  { moreVariantsOnRequest: [] },
  { multiPerson: boolean },
  { tariff: string },
  { downloadButton: string },
];

export interface AgencyCreationError {
  [key: string]: unknown;
  error: boolean;
  parsableDetails: {
    [key: string]: unknown;
    code: string;
    jsonPath: string;
  };
}

export type AgencyData = AgencyCreationError | unknown;

export enum AgencyErrors {
  'agency.vermittlernummer.is.invalid',
  'agency.vermittlernummer.is.unknown',
}

export interface Wertebereich {
  attribut: string;
  vorbelegung: string;
  werte: string[];
}
export interface Versicherung {
  wertebereiche: Wertebereich[];
}

export type OfferWithAgencies = {
  [key: string]: unknown;
  agencyData: AgencyData;
  angebot: {
    [key: string]: unknown;
    versicherungen: Versicherung[];
  };
};
