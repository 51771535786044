/* eslint-disable @typescript-eslint/no-explicit-any */
import { Endpoints } from 'types';

export const CONFIG_BACKEND_URL: string =
  window.CONFIG_BACKEND_URL || (process.env.REACT_APP_BACKEND_URL as string);

export const CONFIG_BACKEND_ENDPOINTS: Record<Endpoints, string> = {
  [Endpoints.requestValidation]: 'form',
  [Endpoints.initialOffer]: 'form/initial-offer',
  [Endpoints.fileDownload]: 'file',
  [Endpoints.login]: 'auth/login',
  [Endpoints.refreshToken]: 'auth/refresh/user-token',
};

export const OFFLINE_DEVELOPMENT =
  process.env.REACT_APP_OFFLINE_DEVELOPMENT === 'true';

export const fileName = 'mailingengine-output.xls';

export const appName = 'PPZ Mailing Engine';

export const uiPath = {
  request: '/request',
  unauthorized: '/unauthorized',
  anyOther: '/*',
};
