import { fileName } from 'config';

import fileDownload from 'js-file-download';
import api from './api';
import { apiUrl } from './endpoints';

export const RequestService = {
  initialOffer: async () => {
    const initialOffer = await api.get(apiUrl.initialOffer);
    return initialOffer;
  },
  finalOffer: async (data: unknown) => {
    const finalOffer = await api.post(apiUrl.finalOffer, data);
    return finalOffer;
  },
  downloadFile: async (businessId: string) => {
    await api
      .get(`${apiUrl.downloadFile}/${businessId}`, {
        responseType: 'blob',
      })
      .then((r) => {
        fileDownload(new Blob([r.data]), fileName);
      });
  },
};
