import { Unauthorized } from 'components/Unauthorized/Unauthorized';
import { uiPath } from 'config';
import { Routes, Route } from 'react-router-dom';

export const UnauthorizedRoutes = () => {
  return (
    <Routes>
      <Route path={uiPath.unauthorized} element={<Unauthorized />} />
    </Routes>
  );
};
