import styles from './Column.module.scss';
import { ColumnProps } from './Column.types';

export const Column: React.FC<ColumnProps> = ({
  span = 12,
  span_xs,
  span_s,
  span_m,
  span_l,
  children,
}) => {
  const validColumnWidth = (prop: string, number?: number) =>
    prop && number && (number >= 1 || number <= 12);

  const spanClassName = validColumnWidth('span', span)
    ? ` esc_col-${span}`
    : '';

  const spanClassNameXS = validColumnWidth('span_xs', span_xs)
    ? ` esc_col-xs-${span_xs}`
    : '';

  const spanClassNameS = validColumnWidth('span_s', span_s)
    ? ` esc_col-s-${span_s}`
    : '';

  const spanClassNameM = validColumnWidth('span_m', span_m)
    ? ` esc_col-m-${span_m}`
    : '';

  const spanClassNameL = validColumnWidth('span_l', span_l)
    ? ` esc_col-l-${span_l}`
    : '';

  const className = `${styles.root} esc_col${spanClassName}${spanClassNameXS}${spanClassNameS}${spanClassNameM}${spanClassNameL}`;

  return <div className={className}>{children}</div>;
};
