import useInterval from 'use-interval';
import React, { useState } from 'react';
import styles from './RefreshUserToken.module.scss';
import { createUrl } from '../../helpers';
import { CONFIG_BACKEND_ENDPOINTS, CONFIG_BACKEND_URL } from '../../config';
import { Endpoints } from '../../types';

export const RefreshUserToken: React.FC = () => {
  const REFRESH_INTERVAL_IN_MILLS = 15 * 1000;
  const [key, setKey] = useState<number>(0);
  const iframeSrc = createUrl(
    CONFIG_BACKEND_URL,
    CONFIG_BACKEND_ENDPOINTS[Endpoints.refreshToken],
  );

  useInterval(
    () => {
      setKey((prevKey) => {
        const nextKey = prevKey + 1;
        return Number.isSafeInteger(nextKey) ? nextKey : 0;
      });
    },
    REFRESH_INTERVAL_IN_MILLS,
    false,
  );

  return (
    <iframe
      data-testid={`refresh-user-token-${key}`}
      className={styles.root}
      title="refresh-user-token"
      src={iframeSrc}
      key={key}
    />
  );
};
