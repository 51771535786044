/* eslint-disable consistent-return */
/* eslint-disable no-case-declarations */
import { variantsQuantityLabels } from 'config/variants-quantity-labels';
import * as actions from 'store/actions';
import { FormDataInitialState } from 'store/constants/initial-state';
import { ActionTypes, MoreVariantsOnRequestChoices } from 'types';
import { VariantMembers, VariantProp } from 'types/app.types';
import { ActionTypeProps } from '../actions/actions-type.types';

const standard = {
  denturesOffered: '',
  toothPreservationOffered: '',
};
const other = {
  changeFrom: '',
  changeTo: '',
};

export const initialState = FormDataInitialState;

const formReducer = (state = initialState, action: ActionTypeProps) => {
  let numberOfVariants: VariantProp[] = [];
  const slicedVariants: VariantProp[] = state.variantsOnRequest.variants.slice(
    0,
    action.payload,
  );
  const variants = variantsQuantityLabels[action.payload];

  const actionTypeVariants =
    state.actionType === ActionTypes.standard ? standard : other;

  switch (action.type) {
    case actions.SET_CONCEPT_NAME:
      return { ...state, conceptName: action.payload };

    case actions.SET_ACTION_NUMBER:
      return { ...state, actionNumber: action.payload };

    case actions.SET_ENTRTY_AGE_FROM:
      return { ...state, entryAgeFrom: action.payload };

    case actions.SET_ENTRTY_AGE_TO:
      return { ...state, entryAgeTo: action.payload };

    case actions.SET_INSURANCE_START_DATE:
      return { ...state, insuranceStartDate: action.payload };

    case actions.SET_INTERMEDIARY_NUMBER:
      return { ...state, intermediaryNumber: action.payload };

    case actions.SET_ACTION_TYPE:
      numberOfVariants = [];
      if (action.payload !== ActionTypes.standard) {
        for (let i = 0; i < state.variantsOnRequest.variants.length; i++) {
          numberOfVariants.push(other);
        }
      }
      if (action.payload === ActionTypes.standard) {
        for (let i = 0; i < state.variantsOnRequest.variants.length; i++) {
          numberOfVariants.push(standard);
        }
      }
      return {
        ...state,
        actionType: action.payload,
        variantsOnRequest: {
          additionalToothPreservation: '',
          additionalDentures: '',
          variants: numberOfVariants,
        },
      };

    case actions.SET_PAYMENT_METHOD:
      return { ...state, paymentMethod: action.payload };

    // handle dynamic form fields at selecting number of variants
    case actions.SET_MORE_VARIANTS_ON_REQUEST:
      let variantsArray: VariantProp[] = [];

      for (let i = 0; i < action.payload; i++) {
        numberOfVariants.push(actionTypeVariants);
      }

      const replaceVariants = () => {
        switch (action.payload) {
          case MoreVariantsOnRequestChoices.none:
            variantsArray = [...slicedVariants];
            return variantsArray;

          case MoreVariantsOnRequestChoices.two:
            if (slicedVariants.length === 1) {
              variantsArray = [...slicedVariants, actionTypeVariants];
            }
            if (slicedVariants.length === 2) {
              variantsArray = [...slicedVariants];
            }
            return variantsArray;

          case MoreVariantsOnRequestChoices.three:
            if (slicedVariants.length === 1) {
              // actionTypeVariants * 2 is on purpuse
              variantsArray = [
                ...slicedVariants,
                actionTypeVariants,
                actionTypeVariants,
              ];
            }
            if (slicedVariants.length === 2) {
              variantsArray = [...slicedVariants, actionTypeVariants];
            }
            return variantsArray;

          default:
            return variantsArray;
        }
      };

      replaceVariants();

      return {
        ...state,
        moreVariantsOnRequest: variants,
        variantsOnRequest: {
          ...state.variantsOnRequest,
          variants: variantsArray.length > 0 ? variantsArray : numberOfVariants,
        },
      };

    case actions.SET_ADDITIONAL_DENTURES:
      return {
        ...state,
        variantsOnRequest: {
          ...state.variantsOnRequest,
          additionalDentures: Object.values(action.payload)[0],
        },
      };

    case actions.SET_ADDITIONAL_TOOTH_PRESERVATION:
      return {
        ...state,
        variantsOnRequest: {
          ...state.variantsOnRequest,
          additionalToothPreservation: Object.values(action.payload)[0],
        },
      };

    case actions.SET_VARIANTS:
      const fullVariantId = Object.keys(action.payload)[0];

      const index = parseInt(fullVariantId.replace(/[^0-9]/g, ''), 10);

      const id = fullVariantId.substring(11) as VariantMembers;

      const copiedVariantsArray = state.variantsOnRequest.variants.map(
        (variant, idx) => {
          if (index === idx) {
            return { ...variant, [id]: Object.values(action.payload)[0] };
          }
          return variant;
        },
      );

      return {
        ...state,
        variantsOnRequest: {
          ...state.variantsOnRequest,
          variants: copiedVariantsArray,
        },
      };

    case actions.SET_AO_MAILING:
      return { ...state, aoMailing: action.payload };

    case actions.SET_MULTIPERSON:
      return { ...state, multiPerson: action.payload };

    case actions.CLEAR_VARIANTS_ARRAY:
      return {
        ...state,
        variantsOnRequest: { ...state.variantsOnRequest, variants: [] },
      };

    default:
      return state;
  }
};

export default formReducer;
