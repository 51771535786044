import { RequestFormErrorMessages } from 'types';
import * as Yup from 'yup';
import { formValidationSchemaCommon } from './form-validation-schema-common';

export const formValidationSchema = Yup.object().shape({
  ...formValidationSchemaCommon,
  variants: Yup.array().of(
    Yup.object().shape({
      changeFrom: Yup.string().required(RequestFormErrorMessages.tariff),
      changeTo: Yup.string().required(RequestFormErrorMessages.tariff),
    }),
  ),
});
