export const fieldsToClear = {
  caseClearAll: [
    'additionalToothPreservation',
    'additionalDentures',
    'variants.0.denturesOffered',
    'variants.0.toothPreservationOffered',
    'variants.0.changeFrom',
    'variants.0.changeTo',
    'variants.1.denturesOffered',
    'variants.1.toothPreservationOffered',
    'variants.1.changeFrom',
    'variants.1.changeTo',
    'variants.2.denturesOffered',
    'variants.2.toothPreservationOffered',
    'variants.2.changeFrom',
    'variants.2.changeTo',
  ],
  caseOne: [
    'variants.2.denturesOffered',
    'variants.2.toothPreservationOffered',
    'variants.2.changeFrom',
    'variants.2.changeTo',
  ],
  caseNone: [
    'variants.1.denturesOffered',
    'variants.1.toothPreservationOffered',
    'variants.2.denturesOffered',
    'variants.2.toothPreservationOffered',
    'variants.1.changeFrom',
    'variants.1.changeTo',
    'variants.2.changeFrom',
    'variants.2.changeTo',
  ],
};
