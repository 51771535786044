import { Grid } from 'components/Grid';
import { GridRow } from 'components/GridRow';
import { Panel } from '@eg/elements/Panel';
import { Column } from 'components/Column';
import styles from './DimmedSection.module.scss';
import { DimmedSectionProps } from './DimmedSection.types';

export const DimmedSection = ({ children, ...props }: DimmedSectionProps) => (
  <Panel className={styles.submitSection} {...props}>
    <Grid>
      <GridRow>
        <Column span_xs={12} span_s={8}>
          {children}
        </Column>
      </GridRow>
    </Grid>
  </Panel>
);
