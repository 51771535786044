import logo from 'assets/svgs/ergo_logo.svg';
import { Column } from 'components/Column';
import { Grid } from 'components/Grid';
import { GridRow } from 'components/GridRow';
import { appName } from 'config';
import styles from './Header.module.scss';

export const Header = ({ ...props }) => (
  <div className={styles.root} {...props}>
    <div className={styles.inner}>
      <Grid>
        <GridRow>
          <Column span={12}>
            <div className={styles.content}>
              <img src={logo} alt="ergo-logo" />
              <h1 className={styles.name}>{appName}</h1>
            </div>
          </Column>
        </GridRow>
      </Grid>
    </div>
  </div>
);
