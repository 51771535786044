import { InputErrorBoxProps } from './InputErrorBox.types';
import styles from './InputErrorBox.module.scss';

export const InputErrorBox: React.FC<InputErrorBoxProps> = ({
  input,
  message,
  ...props
}) => (
  <div className={styles.box} {...props}>
    {input}
    <p data-testid="ErrorMessage" className={styles.text}>
      {message}
    </p>
  </div>
);
