import { loaderLabels } from 'config/loader-labels';
import {
  SET_IS_APP_INITIALIZED,
  SET_IS_DOWNLOAD_BUTTON_LOCKED,
  SET_LOADER_STATE,
  SET_USER_AUTHORIZED,
} from 'store/actions';

export const userInterfaceAction = {
  appInitialize: { type: SET_IS_APP_INITIALIZED, payload: true },
  userAuthorized: { type: SET_USER_AUTHORIZED, payload: true },
  userUnauthorized: { type: SET_USER_AUTHORIZED, payload: false },
  lockAllInputs: { type: SET_IS_DOWNLOAD_BUTTON_LOCKED, payload: true },
  loader: {
    clear: {
      type: SET_LOADER_STATE,
      payload: {
        show: false,
        infoLabel: loaderLabels.clear,
      },
    },
    networkError: {
      type: SET_LOADER_STATE,
      payload: {
        show: true,
        infoLabel: loaderLabels.networkErrorMessage,
      },
    },
    sendData: {
      type: SET_LOADER_STATE,
      payload: {
        show: true,
        infoLabel: loaderLabels.sendingData,
      },
    },
    fileIsDownloading: {
      type: SET_LOADER_STATE,
      payload: {
        show: true,
        infoLabel: loaderLabels.fileDownload,
      },
    },
  },
};
