import { useDispatch, useSelector } from 'react-redux';
import { Header } from 'components/Header/Header';
import { ErrorsProps, RequestForm } from 'components/RequestForm';
import React from 'react';
import { useForm } from 'react-hook-form';
import { formValidationSchema } from 'schemas/form-validation-schema';
import { formValidationSchemaStandard } from 'schemas/form-validation-schema-standard';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionTypes } from 'types';
import { finalOffer } from 'services/api/api-calls/final-offer-call';
import { StoreProps } from 'types/app.types';

export const RequestPage: React.FC = () => {
  const dispatch = useDispatch();
  const actionType = useSelector(
    (state: StoreProps) => state.formData.actionType,
  );
  const data = useSelector((state: StoreProps) => state.formData);

  const schema =
    actionType === 'Standard'
      ? formValidationSchemaStandard
      : formValidationSchema;

  const { register, handleSubmit, formState, trigger, setError, unregister } =
    useForm({
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { errors } = formState;

  function onSubmit() {
    finalOffer({ data, dispatch, setError });
  }

  return (
    <>
      <Header data-testid="Header" />
      <RequestForm
        unregister={unregister}
        register={register}
        actionType={actionType as ActionTypes}
        errors={errors as unknown as ErrorsProps}
        trigger={trigger}
        onSubmit={handleSubmit(onSubmit)}
      />
    </>
  );
};
