// import Button from '@eg/elements/Button';
import logo from 'assets/svgs/ergo_logo.svg';
import warning_logo from 'assets/svgs/warning_logo.png';
import { loaderLabels } from 'config/loader-labels';
import styles from './Unauthorized.module.scss';

export const Unauthorized: React.FC = () => {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.logoBox}>
          <img className={styles.imageSize} src={logo} alt="ergo-logo" />
        </div>
        <div className={styles.logoBox}>
          <img
            className={styles.warningSize}
            src={warning_logo}
            alt="warning-logo"
          />
        </div>
        <div className={styles.infoLabel}>{loaderLabels.unauthorized}</div>
        {/* <Button size="large" className={styles.button}>
          Try a different account
        </Button> */}
      </div>
    </div>
  );
};
