export const loaderLabels = {
  pageIsLoading: 'Seite wird geladen',
  sendingData: 'Daten werden übertragen',
  errorMessage: 'Fehler aufgetreten. Bitte füllen Sie das Formular erneut aus.',
  networkErrorMessage: 'Sie sind nicht mit dem Netzwerk verbunden.',
  connectionTimeOut: 'Verbindungszeit abgelaufen.',
  fileDownload: 'Mailing-Vorlage wird heruntergeladen.',
  unauthorized:
    'Sie sind nicht berechtigt, diese Anwendung zu benutzen. Wenden Sie sich an den Helpdesk, um Zugang zu erhalten.',
  clear: '',
};
